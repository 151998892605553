<template>
  <div id="app">
    <div class="content">
      <the-navbar v-if="!embedded"> </the-navbar>

      <router-view
        :class="embedded ? 'mt-3' : ''"
        :key="$route.fullPath + key"
      ></router-view>

      <vue-internet-checker @status="status" @event="event" />
    </div>
    <div class="dockfooter">
      <b-alert
        dismissible
        variant="danger"
        :show="showPaymentAlert && currentSubscription == null"
      >
        {{ $t("payment-notification") }}

        <span v-if="currentSubscription == null">
          <router-link to="/app/upgrade">
            {{ $t("manage-subscription") }}</router-link
          >
          {{ $t("no-to-lose-acces") }}
        </span>
        <span v-if="currentSubscription != null"
          >{{ $t("next-auto-payment") }}
          {{ Date.parse(currentSubscription.nextPaymentDate) | moment("LLL") }}.
          <router-link to="/app/upgrade">
            {{ $t("manage-subscription-2") }}</router-link
          ></span
        >
      </b-alert>
    </div>

    <div id="somefooter" :key="$route.fullPath + key + 'g'">
      <CourseCard class="" />
      <the-footer v-if="hideFooter" v-show="hideFooter" />
    </div>
  </div>
</template>

<script>
import Keycloak from "keycloak-js";
import vueInternetChecker from "vue-internet-checker";
import $ from "jquery";

import { AXIOS } from "@/shared/http-common.js";

export default {
  name: "app",
  components: {
    CourseCard: () => import("@/features/courses/show-course-card.vue"),
    TheNavbar: () => import("@/widgets/navbar.vue"),
    TheFooter: () => import("@/widgets/footer.vue"),
    vueInternetChecker,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          property: "og:url",
          content: "https://stormbpmn.com",
        }, // here it is just ngrok for my test
        {
          property: "og:image",
          content:
            "https://bpmn2.ru/wp-content/uploads/2022/05/stormbpmncover.jpg",
        },
      ],
    };
  },
  data() {
    return {
      enableCloudMetrics: true,
      showAnonSearchBar: false,
      enableHelpDesk: false,
      myCallbacks: {
        onSkip: this.skipTour,
        onFinis: this.skipTour,
        onPreviousStep: this.myCustomPreviousStepCallback,
        onNextStep: this.myCustomNextStepCallback,
      },

      currentTourStep: 0,
      steps: [
        {
          target: "#v-step-0",
          params: {
            placement: "left",
          },
          content: this.$t("storm-most-effective"),
        },
        {
          target: "#v-step-1",
          params: {
            placement: "top",
          },
          content: this.$t("lets-create-first-process"),
        },
      ],
      myOptions: {
        useKeyboardNavigation: false,
        highlight: true,
        labels: {
          buttonSkip: this.$t("Disable"),
          buttonPrevious: this.$t("Previous"),
          buttonNext: this.$t("Next"),
          buttonStop: this.$t("End"),
        },
      },
      onLine: null,
      diagramIdFromRoute: null,
      key: 1,
      showDefaultToast: true,
    };
  },
  created() {
    this.findIDfromHash();
  },
  watch: {
    refreshKey() {
      this.refreshRoute();
    },
    currentPath() {
      this.key = this.key + 1;
    },
  },
  computed: {
    keycloak() {
      return this.$store.getters.getKeycloak;
    },
    refreshKey() {
      return this.$store.getters.getRefreshKey;
    },
    embedded() {
      if (this.$route.query.embedded === "true") {
        return true;
      } else return false;
    },
    currentUrl() {
      return this.$route;
    },
    currentPath() {
      return this.$route.path;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    username() {
      return this.$store.getters.getUsername;
    },
    planValidTo() {
      return this.$store.getters.getValidTo;
    },
    showPaymentAlert() {
      try {
        var currentDate = Date.parse(new Date());
        var validToDate = this.planValidTo;
        var diff = validToDate - currentDate;
        var days = Math.round(diff / (1000 * 3600 * 24));
        if (days < 5 && days > -1) {
          return true;
        } else return false;
      } catch {
        return false;
      }
    },
    currentSubscription() {
      try {
        return this.$store.getters.getCurrentSubscription;
      } catch {
        return null;
      }
    },
    hideFooter() {
      if (
        this.$route.path.includes("diagram") ||
        this.$route.path.includes("team") ||
        this.$route.path.includes("courses") ||
        this.$route.path.includes("profile")
      ) {
        return false;
      } else return true;
    },
  },
  beforeMount() {
    AXIOS.get("/license").then((response) => {
      this.enableCloudMetrics = response.data.enableCloudMetrics;
      this.enableHelpDesk = response.data.enableHelpDesk;
    });
    if (localStorage.getItem("user-validTo") != null) {
      var validToDate = Date.parse(localStorage.getItem("user-validTo"));
      if (Date.now() > validToDate) {
        this.$store.dispatch("logout");
        this.$router.push("/app/signin");
        this.$bvToast.toast(this.$t("We-signout-you"), {
          title: this.$t("Login-again"),
          solid: true,
          variant: "warning",
          toaster: "b-toaster-bottom-center",
          href: "/app/signin",
        });
      }
    }
    if (localStorage.getItem("storm-anonId") == null) {
      var id = this.makeid(10);
      localStorage.setItem("storm-anonId", id);
      this.$store.commit("setAnonId", id);
    }
    if (localStorage.getItem("storm-anonId") != null) {
      var id2 =  localStorage.getItem("storm-anonId");
      this.$store.commit("setAnonId", id2);
    }
  },
  updated() {
    this.getAllQueryParams();
  },
  mounted() {
    AXIOS.get("/admin/global-settings").then((response) => {
      this.$store.commit("setSettings", response.data);
      try {
        if (
          response.data.find((x) => x.name === "enableAnonSearchPage").value ===
          "true"
        ) {
          this.showAnonSearchBar = true;
        }
      } catch {
        this.showAnonSearchBar = false;
      }
    });
    if (this.keycloak == null && navigator.userAgent !== "ReactSnap") {
      AXIOS.get("/ssoOptions").then((response) => {
        const options = {
          url: response.data.frontUrl,
          realm: response.data.frontRealm,
          clientId: response.data.frontClientId,
        };

        const keycloak = new Keycloak(options);
        keycloak.init({
          checkLoginIframe: false,
        });
        this.$store.commit("setKeycloak", keycloak);
      });
    }

    this.$store.commit(
      "setEmbedded",
      this.$route.query.embedded == "true" ? true : false
    );
    if (this.$route.query.embedded === "true") {
      this.embedded = true;
    }

    setTimeout(() => {
      if (localStorage.getItem("currentTourStep") !== null) {
        this.currentTourStep = parseInt(
          localStorage.getItem("currentTourStep")
        );
      }
    }, 500);
    if (localStorage.getItem("free-alert") != null) {
      this.showDefaultToast = localStorage.getItem("free-alert") === "true";
    }

    if (this.enableCloudMetrics) {
      if (navigator.userAgent !== "ReactSnap") {
        let capcha = document.createElement("script");
        capcha.setAttribute(
          "src",
          "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
        );
        capcha.async = true;
        capcha.defer = true;
        document.head.appendChild(capcha);
      }
    }
    setTimeout(() => {
      if (this.enableHelpDesk) {
        if (navigator.userAgent !== "ReactSnap") {
          /*  let freshdesk = document.createElement('script')
                    freshdesk.setAttribute('src', 'https://widget.freshworks.com/widgets/63000000489.js')
                    freshdesk.setAttribute('type', 'text/javascript')
                    freshdesk.async = true;
                    freshdesk.defer = true;
                    freshdesk.crossorigin = "anonymous";
                    document.head.appendChild(freshdesk) */
          console.log("go happy");
          let happyDesk = document.createElement("script");
          happyDesk.setAttribute(
            "src",
            "https://79hd2-widget.happydesk.ru/widget.js"
          );
          happyDesk.setAttribute("chartset", "utf-8");
          document.head.appendChild(happyDesk);
          setTimeout(() => {
            window.Happydesk.initChat(
              {
                clientId: 7088,
                server: "https://79hd2-widget.happydesk.ru",
                host: "stormbpmn.happydesk.ru",
              },
              {
                page_url: window.location.href,
                user_agent: window.navigator.userAgent,
                language: "ru",
              }
            );
          }, 2000);

          let tawk = document.createElement("script");
          tawk.setAttribute("src", "//fw-cdn.com/2165628/2860422.js");
          tawk.setAttribute("chat", true);
          tawk.async = true;
          tawk.defer = true;
          tawk.crossorigin = "anonymous";
          document.head.appendChild(tawk);
          setTimeout(() => {
            var fresdesh = $("#fc_frame");
            fresdesh.css("bottom", "70px");
          }, 1000);

          let survey = document.createElement("script");
          survey.setAttribute(
            "src",
            "https://cdn.surveyserv.com/widget.min.js"
          );
          document.head.appendChild(survey);

          setTimeout(() => {
            this.$store.commit("setSurvey", window.SurveyWidget);
          }, 2000);
        }
      }
    }, 2000);

    window.fwSettings = {
      widget_id: 63000000489,
    };
    !(function () {
      if ("function" != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();

    setTimeout(() => {
      try {
        if (this.isAuthenticated) {
          var CLF_config = {
            selector: ".changelogfy-widget", // the selector from your html
            app_id: "398088de-f626-4190-a178-016e1cf8df58",
            data: {
              user_id: this.username, // required
              user_email: this.username,
            },
          };
          window.CLF_config = CLF_config;

          window.Happydesk.setUserAccount(this.username, this.username);
          window.Happydesk.disableNameAndEmailInput(true);

          let changelogfy = document.createElement("script");
          changelogfy.setAttribute("async", true);
          changelogfy.setAttribute(
            "src",
            "https://widget.changelogfy.com/index.js"
          );
          document.head.appendChild(changelogfy);
        }
      } catch (e) {
        //
      }
    }, 1000);
  },
  methods: {
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    getAllQueryParams() {
      if (localStorage.getItem("ad_attribution") == null) {
        localStorage.setItem(
          "ad_attribution",
          JSON.stringify(this.$route.query)
        );
      }
    },
    myCustomPreviousStepCallback(currentStep) {
      this.setCurrentStep(currentStep + 1);
    },
    myCustomNextStepCallback(currentStep) {
      this.setCurrentStep(currentStep + 1);
    },

    setCurrentStep(step) {
      this.currentTourStep = step;
      localStorage.setItem("currentTourStep", step);
    },

    status(ele) {
      if (ele == false) {
        this.$bvToast.toast(this.$t("Changes-not-save"), {
          title: this.$t("No-internet-access"),
          toaster: "b-toaster-top-full",
          solid: true,
          noAutoHide: true,
          variant: "danger",
        });
        this.$bvToast.toast(this.$t("Changes-not-save"), {
          title: this.$t("No-internet-access"),
          toaster: "b-toaster-bottom-full",
          solid: true,
          noAutoHide: true,
          variant: "danger",
        });
      }
      if (ele == true) {
        this.$bvToast.toast(this.$t("Changes-saved"), {
          title: this.$t("Connection-is-ok"),
          toaster: "b-toaster-bottom-center",
          solid: true,
          noAutoHide: true,

          variant: "success",
        });
      }
    },
    event(ele) {},
    dismissed() {
      this.showDefaultToast = false;
      localStorage.setItem("free-alert", this.showDefaultToast);
    },
    findIDfromHash() {
      this.diagramIdFromRoute = this.$route.hash.substring(15);
      if (this.diagramIdFromRoute.length > 10 && this.$route.hash != null) {
        this.$router.push("/app/diagram/" + this.diagramIdFromRoute);
      }
    },
    refreshRoute() {
      this.key = this.key + 1;
    },
  },
};
</script>

<style lang="scss">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
@import "@/app/assets/_variables.scss";
// Import Bootstrap and BootstrapVue source SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

.ti-input {
  border: 0px solid #ccc;
  padding: 0px;
}

a {
  color: #77a3f0;
  text-decoration: none;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(119, 163, 240);
}

.b-sidebar {
  width: 500px;
}

.routerclass {
  margin-top: 3rem !important;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1em;
  height: 1.5em;
  margin-left: -5px;
  vertical-align: middle;
  background: no-repeat center center;
  background-size: 100% 100%;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.jumbotron {
  margin-bottom: 0px;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 15px;
  /* 纵向滚动条*/
  height: 7px;
  /* 横向滚动条 */
  background-color: #fff;
  transition: opacity 120ms ease-out;
  border-radius: 10px;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 10px;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #d5d5d5;
  border-radius: 10px;
}

.block4 {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 0px;
}

.content {
  min-height: calc(100vh - 50px);
}

.dockfooter {
  margin-left: 50px;
  position: fixed;
  bottom: 0;
  -webkit-box-shadow: 4px 4px 27px 0px rgba(34, 60, 80, 0.18);
  -moz-box-shadow: 4px 4px 27px 0px rgba(34, 60, 80, 0.18);
  box-shadow: 4px 4px 27px 0px rgba(34, 60, 80, 0.18);
  right: 0;
  width: 100%;

  z-index: 9923423499;
}
</style>
