import Vue from "vue";
import Vuex from "vuex";
import { event, set } from "vue-analytics";
import cheatSheet from "./modules/CheatSheet";

Vue.use(Vuex);

function getUserSettings() {
  
  try {
  if (localStorage.getItem("user-userSettings") != null || localStorage.getItem("user-userSettings") != undefined) {
  return JSON.parse(localStorage.getItem("user-userSettings"))
 } else return []}
 catch {
  return []
 }

}

const state = {
  license: localStorage.getItem("user-license") || "",
  token: localStorage.getItem("user-token") || "",
  role: localStorage.getItem("user-role") || "",
  username: localStorage.getItem("user-name") || "",
  inTeam: localStorage.getItem("user-inTeam") || "",
  teamId: localStorage.getItem("user-teamId") || "",
  teamName: localStorage.getItem("user-teamName") || "",
  fullAccess: localStorage.getItem("user-fullAccess") || "",
  userId: localStorage.getItem("user-id") || "",
  plan: localStorage.getItem("user-plan") || "",
  validTo: localStorage.getItem("user-validTo") || "",
  currentSubscription: JSON.parse(localStorage.getItem("user-currentSubscription")) || "",
  enabledIntegration: JSON.parse(localStorage.getItem("user-enabledIntegration")) || "",
  authorities: localStorage.getItem("authorities") || "",
  teamRights: localStorage.getItem("user-teamRights") || "",
  userSettings: getUserSettings(),
  diagramType: "",
  keycloak: null,
  embedded: false,
  openComment: false,
  myPermission: null,
  diagram: {
    id: null,
    name: null,
    description: null,
    body: null,
    public: true,
    createdOn: null,
    updatedOn: null,
  },
  beforeDestroyBody: localStorage.getItem("beforeDestroyBody") || null,
  clickedElement: null,
  clickedAddElement: null,
  commentedElement: null,
  diagramInSVG: null,
  checkDiagram: false,
  folderSideBarOpen: false,
  refreshKey: 1,
  window: {
    width: 0,
    height: 0,
  },
  clearOverlay: false,
  lint: {
    lintResult: null,
    countOfElements: 0,
  },
  diagramVersion: {},
  loadComments: false,
  centerOnElement: null,
  fireAutoCreate: false,

  camundaValidateError: [],
  settings: [],
  survey: null,
  autosaveDate: null,
  removedPalleteActions: [],
  /// excamad
  serverStatus: true,
  selectedCamundaServer: null,
  editorClosed: false,
  overlaysState: null,
  scoringSettings: [],
  selectedElement: null,
  anonId: null
};

const getters = {
  getAnonId: (state) => {
    return state.anonId;
  },
  getValidTo: (state) => {
    return Date.parse(state.validTo);
  },
  getCurrentSubscription: (state) => {
    return state.currentSubscription;
  },
  getScoringSettings: (state) => {
    return state.scoringSettings;
  },
  getSelectedElement: (state) => {
    return state.selectedElement;
  },
  getEditorState: (state) => {
    return state.editorClosed;
  },
  getRemovedPalleteActions: (state) => {
    return state.removedPalleteActions;
  },
  isAuthenticated: (state) => {
    if (state.token != null && state.token != "") {
      return true;
    } else {
      return false;
    }
  },
  getOverlaysState: (state) => {
    return state.overlaysState;
  },
  isAdmin: (state) => {
    if (state.role === "admin") {
      return true;
    } else {
      return false;
    }
  },
  getLint: (state) => {
    return state.lint;
  },
  getCamundaValidateError: (state) => {
    return state.camundaValidateError;
  },
  getOpenComment: (state) => {
    return state.openComment;
  },
  getStatus: (state) => {
    return state.diagram.status;
  },
  getMyPermission: (state) => {
    return state.myPermission;
  },
  getSettings: (state) => {
    return state.settings;
  },
  getKeycloak: (state) => {
    return state.keycloak;
  },
  getAutoSaveDate: (state) => {
    return state.autosaveDate;
  },
  getAutoCreate: (state) => {
    return state.fireAutoCreate;
  },
  getBeforeDestroyBody: (state) => {
    return state.beforeDestroyBody;
  },
  getFolderSidebarOpen: (state) => {
    return state.folderSideBarOpen;
  },
  getCommentedElement: (state) => {
    return state.commentedElement;
  },

  getUsername: (state) => {
    return state.username.toLowerCase();
  },
  getInTeam: (state) => {
    if (
      state.plan == "TEAM" ||
      state.plan == "TEAM_TRIAL" ||
      state.plan == "ENTERPRISE"
    ) {
      return true;
    } else return false;
  },
  getCenterElement: (state) => {
    setTimeout(() => {
      state.centerOnElement = null;
    }, 1000);
    return state.centerOnElement;
  },
  getDiagramInSVG: (state) => {
    return state.diagramInSVG;
  },
  getDiagramAutosaveIndex: (state) => {
    return state.diagram.autosaveIndex;
  },
  getDiagramType: (state) => {
    return state.diagramType;
  },
  getDiagram: (state) => {
    return state.diagram;
  },
  getRefreshKey: (state) => {
    return state.refreshKey;
  },
  getDiagramName: (state) => {
    return state.diagram.name;
  },
  getCheckDiagram: (state) => {
    return state.checkDiagram;
  },
  getClearOverlay: (state) => {
    return state.clearOverlay;
  },
  getAuthorities: (state) => {
    return state.authorities;
  },
  getToken: (state) => {
    return state.token;
  },
  getCurrentDiagramBody: (state) => {
    return state.diagram.body;
  },
  getCurrentDiagram: (state) => {
    var diagram = state.diagram;
    diagram.type = state.diagramType;

    diagram.svg = btoa(unescape(encodeURIComponent(state.diagramInSVG)));

    return diagram;
  },
  getCurrentDiagramId: (state) => {
    return state.diagram.id;
  },
  getDiagramVersion: (state) => {
    return state.diagramVersion;
  },
  getEmbedded: (state) => {
    return state.embedded;
  },
  getCurrentDiagramPublicState: (state) => {
    return state.diagram.public;
  },
  getCurrentDiagramWithoutBody: (state) => {
    var obj = {
      id: state.diagram.id,
      name: state.diagram.name,
      description: state.diagram.description,
      public: state.diagram.public,
      createdOn: state.diagram.createdOn,
      updatedOn: state.diagram.updatedOn,
      updateBy: state.diagram.updateBy,
      favored: state.diagram.favored,
      favoritesCount: state.diagram.favoritesCount,
      authorUsername: state.diagram.authorUsername,
      you: state.diagram.you,
    };
    return obj;
  },
  getClickedElement: (state) => {
    return state.clickedElement;
  },

  getClickAddElements: (state) => {
    return state.clickedAddElement;
  },

  getLicense: (state) => {
    return state.license;
  },
  getWindow: (state) => {
    return state.window;
  },
  getJiraEnabled: (state) => {
    try {
      return state.enabledIntegration.indexOf("JIRA") > -1;
    } catch {
      return false;
    }
  },
  getTeamId: (state) => {
    return state.teamId;
  },
  getFullAccess: (state) => {
    return state.fullAccess;
  },
  getUserId: (state) => {
    return state.userId;
  },
  getPlan: (state) => {
    return state.plan;
  },
  getCommentPermission: (state) => {
    if (state.myPermission == "COMMENT" || state.myPermission == "EDIT") {
      return true;
    }
    return false;
  },
  getViewPermission: (state) => {
    if (state.myPermission != "EDIT" && state.myPermission != "NONE") {
      return true;
    }
    return false;
  },
  getEditPermission: (state) => {
    if (state.myPermission == "EDIT") {
      return true;
    }
    return false;
  },

  getLoadPermission: (state) => {
    if (state.myPermission != "NONE") {
      return true;
    }
    return false;
  },
  getCurrentTeamPlan: (state) => {
    if (
      state.plan == "TEAM" ||
      state.plan == "TEAM_TRIAL" ||
      state.plan == "ENTERPRISE"
    ) {
      return true;
    } else return false;
  },
  getTeamTrial: (state) => {
    try {
    if (state.plan == "TEAM_TRIAL") {
      return true;
    } else return false;
  } catch {
    return false
  }
  },
  isPersonal: (state) => {
    try {
    if (state.plan != "TEAM_TRIAL" && 
        state.plan != "TEAM" && 
        state.plan != "ENTERPRISE") {
      return true;
    } else return false;
  } catch {
    return false;
  }
  },

  getEnterpriseEnabled: (state) => {
    if (state.plan == "ENTERPRISE") {
      return true;
    } else return false;
  },
  getloadComments: (state) => {
    return state.loadComments;
  },
  getUser: (state) => {
    var obj = {
      username: state.username,
      teamName: state.teamName,
      teamId: state.teamId,
    };
    return obj;
  },
  getCommentObj: (state) => {
    var obj = {
      diagramId: state.diagram.id,
      element: state.commentedElement,
    };
    return obj;
  },
  getServerStatus: (state) => {
    return state.serverStatus;
  },
  getTeamRights: (state) => {
    return state.teamRights;
  },
  getIAmAdmin: (state) => {
    if (state.teamRights == "ADMIN") {
      return true;
    } else {
      return false;
    }
  },
  isDiagramHasId: (state) => {
    if (state.diagram.id != null) {
      return true;
    } else return false;
  }, //excamad
  getSelectedCamundaServer: (state) => {
    return state.selectedCamundaServer;
  },
  getUserSettings: (state) => {
    return state.userSettings;
  },
  getUserSettingsOverlay: (state) => {

    var a = [] //to return
    
    try {
    if (state.userSettings != null || ("userSettings" in state) && state.userSettings.length > 0) {
       
      var obj = state.userSettings.find( e=> e.setting === "overlayDefaultSettings")
       if (obj != null && obj != undefined) {
        a = JSON.parse(obj.value)
       } 
    } 
    return a;
  } catch (e) {
    return a;
  }

    
  },
};

const mutations = {
  auth_login: (state, user) => {
    localStorage.setItem("user-token", user.token);
    localStorage.setItem("user-name", user.username);
    localStorage.setItem("user-authorities", user.roles);
    localStorage.setItem("user-inTeam", user.inTeam);
    localStorage.setItem("user-teamId", user.teamId);
    localStorage.setItem("user-fullAccess", user.fullAccess);
    localStorage.setItem("user-id", user.userId);
    localStorage.setItem("user-plan", user.plan);
    localStorage.setItem("user-validTo", user.validTo);
    localStorage.setItem("user-currentSubscription", JSON.stringify(user.currentSubscription))

    localStorage.setItem(
      "user-enabledIntegration",
      JSON.stringify(user.enabledIntegration)
    );
    localStorage.setItem("user-teamName", user.teamName);
    localStorage.setItem("user-license", JSON.stringify(user.license));
    localStorage.setItem("user-teamRights", user.teamRights);
    localStorage.setItem(
      "user-userSettings",
      JSON.stringify(user.userSettings)
    );

    state.token = user.token;
    state.teamId = user.teamId;
    state.fullAccess = user.fullAccess;
    state.userId = user.userId;
    state.teamName = user.teamName;
    state.plan = user.plan;
    state.validTo = user.validTo;
    state.currentSubscription = user.currentSubscription;
    state.enabledIntegration = user.enabledIntegration;
    state.username = user.username;
    state.inTeam = user.inTeam;
    state.authorities = user.roles;
    state.license = user.license;
    state.teamRights = user.teamRights;
    state.userSettings = user.userSettings;
  },
  refresh_plan: (state, user) => {
    localStorage.setItem("user-inTeam", user.inTeam);
    state.inTeam = user.inTeam;

    localStorage.setItem("user-plan", user.plan);
    state.plan = user.plan;

    localStorage.setItem("user-teamName", user.teamName);
    state.teamName = user.teamName;

    localStorage.setItem("user-license", JSON.stringify(user.license));
    state.license = user.license;

    localStorage.setItem("user-teamRights", user.teamRights);
    state.teamRights = user.teamRights;
  },
  refresh_user_settings: (state, user) => {
    localStorage.setItem("user-userSettings", JSON.stringify(user));
    state.userSettings = user;
  },
  auth_logout: () => {
    state.token = "";
    state.role = "";
    state.username = "";
    state.inTeam = null;
    state.teamId = null;
    state.fullAccess = null;
    state.userId = null;
    state.teamName = null;
    state.plan = null;
    state.validTo = null;
    state.currentSubscription = null;
    state.enabledIntegration = null;
    state.authorities = [];
    state.license = null;
    state.teamRights = null;
    state.userSettings = [];
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-role");
    localStorage.removeItem("user-name");
    localStorage.removeItem("user-authorities");
    localStorage.removeItem("user-inTeam");
    localStorage.removeItem("user-teamId");
    localStorage.removeItem("user-fullAccess");
    localStorage.removeItem("user-id");
    localStorage.removeItem("user-teamName");
    localStorage.removeItem("user-plan");
    localStorage.removeItem("user-validTo");
    localStorage.removeItem("user-currentSubscription");
    localStorage.removeItem("user-enabledIntegration");
    localStorage.removeItem("user-license");
    localStorage.removeItem("user-teamRights");
    localStorage.removeItem("user-userSettings");
  },
  setCurrentDigaramBody(state, body) {
    state.diagram.body = body;
  },
  setLint: (state, lint) => {
    state.lint = lint;
  },
  setCheckDiagram(state, check) {
    state.checkDiagram = check;
    setTimeout(() => {
      state.checkDiagram = false;
    }, 10);
  },
  fireLoadComments(state, fire) {
    state.loadComments = fire;
  },
  setClearDiagramBody(state, clear) {
    state.autosaveDate = null;
    state.diagram.body = null;
    state.diagram.svg = null;
  },
  setClearAutoSaveIndex(state, clear) {
    state.diagram.autosaveIndex = null;
  },
  setAutoSaveDate(state, date) {
    state.autosaveDate = date;
  },
  setClearDiagram(state, clear) {
    state.diagram.id = null;
    state.diagram.authorUsername = null;
    state.diagram.favored = null;
    state.diagram.favoritesCount = null;
    state.diagram.incommingLinks = [];
    state.diagram.linkedDiagramId = null;
    state.diagram.linkedDiagramName = null;
    state.diagram.outcommingLinks = null;
    state.diagram.processType = null;
    state.diagram.status = null;
    state.diagram.svg = null;
    state.diagram.tags = [];
    state.diagram.updateBy = null;
    state.diagram.updatedOn = null;
    state.diagram.version = null;
    state.diagram.versionNumber = null;
    state.diagram.you = false;
    state.diagram.autosaveIndex = null;
    state.diagram.name = null;
    state.diagram.body = null;
    state.diagram.svg = null;
    state.diagramType = null;
    state.diagram.description = null;
    state.diagram.public = null;
    state.diagram.createdOn = null;
    state.diagram.updateOn = null;
  },
  setAutosaveIndex(state, index) {
    state.diagram.autosaveIndex = state.diagram.autosaveIndex + 1;
  },
  setSelectedElement(state, element) {
    state.selectedElement = element;
  },
  setCommentedElement(state, element) {
    state.commentedElement = element;
  },
  setEditorState(state, editorState) {
    state.editorClosed = editorState;
  },
  setSurvey(state, survey) {
    state.survey = survey;
  },
  setCamundaServer(state, server) {
    state.selectedCamundaServer = server;
  },

  fireSurvey(state, email) {
    state.survey.init({
      type: "default",
      delay: 50,
      include_unsubscribe: true,
      survey_id: "a4d49768-909b-41cf-bb37-6b43f8801c13",
      survey_collector_id: "SC_-2",
      contact: {
        first_name: "",
        last_name: "",
        work_email: email,
      },
    });
  },
  setOverlaysState(state, key) {
    state.overlaysState = key;
  },
  setRefreshKey(state, key) {
    state.refreshKey = state.refreshKey + key;
  },
  setCamundaValidateError(state, error) {
    state.camundaValidateError = error;
  },
  setWindow(state, window) {
    state.window = window;
  },
  setPlan(state, plan) {
    state.plan = plan;
  },
  setRemovedPalleteActions(state, removedPalleteActions) {
    state.removedPalleteActions = removedPalleteActions;
  },

  setScoringSettings(state, scoringSettings) {
    state.scoringSettings = JSON.parse(scoringSettings);
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
  setAnonId(state, id) {
   state.anonId= id;
  },
  setDiagramType(state, type) {
    state.diagramType = type;
  },
  setKeycloak(state, keycloak) {
    state.keycloak = keycloak;
  },
  setCenterElement(state, element) {
    state.centerOnElement = element;
  },
  setMyPermission(state, myPermission) {
    state.myPermission = myPermission;
  },
  setEmbedded(state, embedded) {
    state.embedded = embedded;
  },
  setClickedEvent(state, element) {
    state.clickedElement = element;
  },
  setOpenComment(state, element) {
    state.openComment = element;
  },

  sendClickAddElements(state, element) {
    state.clickedAddElement = element;
    state.clickedElement = element;
  },
  setDiagram(state, diagram) {
    state.diagram = diagram;
  },
  setFolderSideBarOpen(state, open) {
    state.folderSideBarOpen = open;
  },
  setAutoCreate(state, value) {
    state.fireAutoCreate = value;
  },
  clearDiagram(state) {
    var obj = {
      id: null,
      name: null,
      description: null,
      body: null,
      public: true,
      createdOn: null,
      updatedOn: null,
    };
    state.diagramInSVG = null;
    state.fireAutoCreate = false;
    state.diagram = obj;
  },
  setDiagramVersion(state, diagram) {
    state.diagramVersion = diagram;
  },

  setInTeam(state, inTeam) {
    localStorage.setItem("user-inTeam", inTeam);
    state.inTeam = inTeam;
  },
  setDiagramWithOutBody(state, diagram) {
    diagram["body"] = state.diagram.body;
    state.diagram = diagram;
  },
  setDiagramInSVG(state, svg) {
    state.diagramInSVG = svg;
  },
  setClearOverlay(state, clear) {
    state.clearOverlay = clear;
    setTimeout(() => {
      state.clearOverlay = false;
    }, 10);
  }, //excama
  changeServerStatus(state, status) {
    state.serverStatus = status;
  },
};

const actions = {
  login: (context, user) => {
    context.commit("auth_login", user);
  },
  refreshPlan: (context, plan) => {
    context.commit("refresh_plan", plan);
  },
  logout: (context) => {
    context.commit("auth_logout");
  },
  refreshUserSettings: (context, userSettings) => {
    context.commit("refresh_user_settings", userSettings);
  },
};

export const store = new Vuex.Store({
  modules: {
    cheatSheet,
  },
  state,
  getters,
  mutations,
  actions,
});
export default store;
